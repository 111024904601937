import React from 'react';
import { Link } from 'gatsby';
import { Career } from '../../types/content/careers';

function CareersListBox(career: Career) {
  const { title, tag, id, location } = career;
  return (
    <Link to={`/careers/detail?posting=${id}`}>
      <div
        className="group box-border flex justify-between flex-col-reverse md:flex-row items-start border border-gray-500 rounded-xl flex-none p-4 mt-4 md:p-4.2 hover:border-black cursor-pointer"
        data-testid="career-list-box"
      >
        <div className="flex flex-col items-start">
          <p
            className="not-italic font-semibold text-lg leading-6 flex items-center text-gray-800 mt-2 md:mt-0"
            data-testid="career-title"
          >
            {title}
            <span className="ml-2 w-3.5 self-center invisible group-hover:visible transition duration-500 ease-out opacity-0 group-hover:opacity-100 transform -translate-x-1 group-hover:translate-x-0">
              <svg
                className="w-full h-full fill-current"
                width="25"
                height="16"
                viewBox="0 0 25 16"
                fill="black"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g>
                  <path
                    strokeWidth="20"
                    d="M24.7071 8.70711C25.0976 8.31658 25.0976 7.68342 24.7071 7.29289L18.3431 0.928932C17.9526 0.538408 17.3195 0.538408 16.9289 0.928932C16.5384 1.31946 16.5384 1.95262 16.9289 2.34315L22.5858 8L16.9289 13.6569C16.5384 14.0474 16.5384 14.6805 16.9289 15.0711C17.3195 15.4616 17.9526 15.4616 18.3431 15.0711L24.7071 8.70711ZM0.5 9H24V7H0.5V9Z"
                  />
                  <rect width="25" height="16" fill="none" />
                </g>
              </svg>
            </span>
          </p>
          <div
            className="border box-border rounded-6xl border-secondary mt-3"
            data-testid="tag-container"
          >
            <p className="font-semibold text-sm leading-5 text-center capitalize text-gray-800 py-1 px-4">
              {tag}
            </p>
          </div>
        </div>

        <div>
          <h3 className="text-sm">{location}</h3>
        </div>
      </div>
    </Link>
  );
}

export default CareersListBox;
