import * as React from 'react';
import CareersList from '../../components/careers/CareersList';
import DocumentHead from '../../components/DocumentHead';
import { careersContent } from '../../content/index';
// @ts-ignore

const CareerPage = () => {
  return (
    <div
      data-testid="careers-page-container"
      className="pt-11 px-3 grid grid-cols-4 md:grid-cols-8 lg:grid-cols-12 md:pt-8 md:px-68px md:mt-8 mb-9 md:mb-11"
    >
      <DocumentHead content={careersContent.metadata} />
      <div className="col-span-4 md:col-span-8 lg:col-span-9">
        <div data-testid="hero-container">
          <div className="max-w-max w-full">
            <div data-testid="heroContainer">
              <h2
                data-testid="hero-header"
                className="font-bold text-2xl tracking-tighter md:font-extrabold md:text-4xl md:leading-48px lg:tracking-normal"
              >
                {careersContent.hero.header}
              </h2>
              <p
                data-testid="hero-description"
                className="mt-3 md:mt-5 font-normal text-base tracking-tighter lg:text-lg lg:tracking-normal md:w-9/12"
              >
                {careersContent.hero.description}
              </p>
            </div>
          </div>
        </div>
        <div data-testid="jobs-container">
          <div className="max-w-max w-full mt-5 md:mt-6">
            <div>
              <CareersList />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CareerPage;
