import React from 'react';
import { careersContent } from '../../content/index';
import CareersListBox from './CareersListBox';

function CareersList() {
  const { careers } = careersContent;
  const { noposition } = careersContent.hero;
  return (
    <React.Fragment>
      <div>
        {careers && careers.length > 0 ? (
          careers.map(career => <CareersListBox key={career.id} {...career} />)
        ) : (
          <h2 data-testid="hero-noposition">{noposition}</h2>
        )}
      </div>
    </React.Fragment>
  );
}

export default CareersList;
